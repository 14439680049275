import axios from 'axios'

export const LocationTypeApi = {
    async Table(data){
        const response = await axios.post('/api/locationtype/table',data)
        return response
    },
    async CreateOrUpdate(update,data){
        const response = await axios.post(update ? '/api/locationtype/update' : '/api/locationtype/create' , data)
        return response
    },
    async Delete(data){
        const response = await axios.post(`/api/locationtype/delete/${data}`)
        return response
    },
    async List(){
        const response = await axios.get(`/api/locationtype/list`)
        return response
    }
}